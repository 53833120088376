//index.js
import Repository from "../../../repositories/RepositoryFactory";
const StatisticsRepository = Repository.get("statistics");
export default {
    async getDashboardStatisticsBasic(context, payload) {
        // console.log("Here");
        var response = await StatisticsRepository.getDashboardStatisticsBasic(payload);
        context.commit("getDashboardStatisticsBasic", response); //commit will call mutation name

        return response;
    },
};
