import { createStore } from "vuex";
import axios from "axios";
import staffModule from "./modules/staff/index.js";
import userModule from "./modules/user/index.js";
import receiptModule from "./modules/receipt/index.js";
import deviceModule from "./modules/device/index.js";
import loggedModule from "./modules/logged/index.js";
import statisticsModule from "./modules/statistics/index.js";
const BaseUrl = process.env.VUE_APP_BASE_URL;
export default createStore({
  modules: {
    staff: staffModule,
    user: userModule,
    receipt: receiptModule,
    device: deviceModule,
    logged: loggedModule,
    statistics: statisticsModule,
  },
  state: {
    token: localStorage.getItem("token") || "",
    status: "",
    counters: null,
    error: null,
    isLoggedIn: localStorage.getItem("token") ? true : false,
  },
  getters: {
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
    // isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
  },
  mutations: {
    login(state) {
      state.isLoggedIn = true;
    },
    logout(state) {
      state.isLoggedIn = false;
    },
    SET_counters(state, counters) {
      state.counters = counters;
    },
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, { token, user }) {
      state.status = "success";
      state.token = token;
      state.user = user;
    },
    auth_error(state) {
      state.status = "error";
    },
    // logout(state) {
    //   state.status = "";
    //   state.token = "";
    //   state.user = "";
    // },

    SET_error(state) {
      state.error = "Invalid UserName or password!";
      // return false;
    },
  },
  actions: {
    login(context) {
      context.commit('login'); //commit will call mutation name
    },
    logout(context) {
      context.commit('logout'); //commit will call mutation name
    },
    getCounters: async ({ commit }) => {
      await axios
        .get(`${BaseUrl}/api/Counter`)
        .then(function (response) {
          commit("SET_counters", response.data.result);
        })
        .catch((error) => console.log(error));
    },
  },
});
