export default {
  serviceStatusTechs: (state) => {
    return state.serviceStatusTechs;
  },
  devices: (state) => {
    return state.devices;
  },
  services: (state) => {
    return state.services;
  },
  spareParts: (state) => {
    return state.spareParts;
  },
  brandOptions: (state) => {
    return state.brandOptions;
  },
  colorOptions: (state) => {
    return state.colorOptions;
  },
  serviceOptions: (state) => {
    return state.serviceOptions;
  },
  sparePartOptions: (state) => {
    return state.sparePartOptions;
  },
  serviceNameOptions: (state) => {
    return state.serviceNameOptions;
  },
  serviceCategoryOptions: (state) => {
    return state.serviceCategoryOptions;
  },
  sparePartTypeOptions: (state) => {
    return state.sparePartTypeOptions;
  },
  sparePartCategoryOptions: (state) => {
    return state.sparePartCategoryOptions;
  },
  defectOptions: (state) => {
    return state.defectOptions;
  },
  deviceTypes: (state) => {
    return state.deviceTypes;
  },
  deviceCategories: (state) => {
    return state.deviceCategories;
  },
};
