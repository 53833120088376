import StaffRepository from "./StaffRepository";
import ReceiptRepository from "./ReceiptRepository";
import DeviceRepository from "./DeviceRepository";
import LoggedRepository from "./LoggedRepository";
import StatisticsRepository from "./StatisticsRepository";

const repositories = {
  staffs: StaffRepository,
  receipts: ReceiptRepository,
  devices: DeviceRepository,
  logged: LoggedRepository,
  statistics: StatisticsRepository,
};
export default {
  get: (name) => repositories[name],
};
