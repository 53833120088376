import deviceMutations from "./mutations.js";
import deviceActions from "./actions.js";
import deviceGetters from "./getters.js";

export default {
  namespaced: true, // put true if want enable modules
  state() {
    return {
      devices: null,
      services: null,
      spareParts: null,
      technicalServiceDetails: null,
      sparePartDetails: null,
      brandOptions: null,
      colorOptions: null,
      serviceOptions: null,
      sparePartOptions: null,
      serviceNameOptions: null,
      serviceCategoryOptions: null,
      sparePartTypeOptions: null,
      sparePartCategoryOptions: null,
      defectOptions: null,
      serviceStatusTechs: null,
      deviceTypes:null,
      deviceCategories:null,
    };
  },
  mutations: deviceMutations,
  actions: deviceActions,
  getters: deviceGetters,
};
