export default {
  staffs: (state) => {
    return state.staffs;
  },
  counterGroups: (state) => {
    return state.counterGroups;
  },
  counters: (state) => {
    return state.counters;
  },
  outlets: (state) => {
    return state.outlets;
  },
  organizations: (state) => {
    return state.organizations;
  },
  walkinServiceTypes: (state) => {
    return state.walkinServiceTypes;
  },
  staffPositions: (state) => {
    return state.staffPositions;
  },
  totalStaffs: (state) => {
    return state.totalStaffs;
  },
  totalCounterGroups: (state) => {
    return state.totalCounterGroups;
  },
  outletsOnly: (state) => {
    return state.outletsOnly;
  },
};
