import statisticsMutations from "./mutations.js";
import statisticsActions from "./actions.js";
import statisticsGetters from "./getters.js";

export default {
  namespaced: true, // put true if want enable modules
  state() {
    return {
      staff: null,
      dashboardStatisticsBasic: { data: {} },
    };
  },
  mutations: statisticsMutations,
  actions: statisticsActions,
  getters: statisticsGetters,
};
