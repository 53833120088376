export default {
  SET_staffs: (state, staffs) => {

    state.totalStaffs = staffs.totalCount;

    staffs.results.forEach((y) => {
      if (y.hasCounter) {
        y.counter = y.counterToStaffAdjustmentLogs[0].counter;
      }
      y.staffPosition.staffs = [];
    });
    state.staffs = staffs.results;
  },
  POST_staff: (state, staff) => {
    if (staff.hasCounter) {
      staff.counter = staff.counterToStaffAdjustmentLogs[0].counter;
    } else {
      staff.counter = null;
    }
    staff.staffPosition.staffs = [];
    state.staffs.unshift(staff);
    state.staff = staff;
  },
  PUT_staff: (state, staff) => {
    if (staff.hasCounter) {
      staff.counter = staff.counterToStaffAdjustmentLogs[0].counter;
    } else {
      staff.counter = null;
    }
    // staff.staffPosition.staffs=[];
    state.staffs.find((x) => x.id == staff.id).value = staff;
    state.staff = staff;
  },

  SET_outlets: (state, outlets) => {
    outlets.forEach((x) => { x.totalCounters = x.counters.length });
    state.outlets = outlets;
  },
  POST_outlet: (state, outlet) => {

    var tempOutlet =
    {
      active: outlet.active,
      counterGroups: [],
      counters: [],
      createdAt: outlet.createdAt,
      createdBy: outlet.createdBy,
      description: outlet.description,
      id: outlet.id,
      isActive: outlet.isActive,
      isHQ: outlet.isHQ,
      name: outlet.name,
      organization: outlet.organization,
      organizationId: outlet.organizationId,
      recordID: outlet.recordID,
      staffs: outlet.staffs,
      updatedAt: outlet.updatedAt,
      updatedBy: outlet.updatedBy,
    };

    outlet.totalCounters = outlet.counters.length;
    //  x.outlet=outlet; x.outlet.counters=[];x.outlet.counterGroups=[];x.outlet.organization=[];
    outlet.counters.forEach((x) => { x.outlet = tempOutlet; state.counters.unshift(x); });
    state.outlets.unshift(outlet);
    state.outlet = outlet;
  },
  PUT_outlet: (state, outlet) => {
    // var tempOutlet = outlet;
    // tempOutlet.counterGroups = [];
    // tempOutlet.counters = [];

    var tempOutlet =
    {
      active: outlet.active,
      counterGroups: [],
      counters: [],
      createdAt: outlet.createdAt,
      createdBy: outlet.createdBy,
      description: outlet.description,
      id: outlet.id,
      isActive: outlet.isActive,
      isHQ: outlet.isHQ,
      name: outlet.name,
      organization: outlet.organization,
      organizationId: outlet.organizationId,
      recordID: outlet.recordID,
      staffs: outlet.staffs,
      updatedAt: outlet.updatedAt,
      updatedBy: outlet.updatedBy,
    };

    var prevOutletData = state.outlets[state.outlets.findIndex((x) => x.id == outlet.id)];
    outlet.totalCounters = outlet.counters.length;
    outlet.organization = prevOutletData.organization;
    state.outlets[state.outlets.findIndex((x) => x.id == outlet.id)] = outlet;
    state.counters = state.counters.filter((x) => { return (x.outletId != outlet.id); });
    outlet.counters.forEach((x) => { x.outlet = tempOutlet; state.counters.unshift(x); });
    state.outlet = outlet;
  },

  SET_counterGroups: (state, counterGroups) => {
    state.totalCounterGroups = counterGroups.totalCount;
    counterGroups.results.forEach((y) => {
      y.walkinServiceTypes = [];
      y.counters = [];
      y.outlet.counters = [];
      y.outlet.updatedAt = null;
      y.outlet.updatedBy = null;

      var tempCounterGroup = {
        active: y.active,
        counterToCounterGroupAdjustmentLogs: [],
        counterGroupToWalkinServiceTypeAdjustmentLogs: [],
        createdAt: y.createdAt,
        createdBy: y.createdBy,
        description: y.description,
        id: y.id,
        imageUrl: y.imageUrl,
        isActive: y.isActive,
        lastDate: y.lastDate,
        lastTime: y.lastTime,
        name: y.name,
        outlet: null,
        outletId: y.outletId,
        recordID: y.recordID,
        startDate: y.startDate,
        startTime: y.startTime,
        updatedAt: y.updatedAt,
        updatedBy: y.updatedBy,
      };

      y.counterGroupToWalkinServiceTypeAdjustmentLogs.forEach((x) => { x.walkinServiceType.counterGroupToWalkinServiceTypeAdjustmentLogs = []; y.walkinServiceTypes.push(x.walkinServiceType); });
      y.counterToCounterGroupAdjustmentLogs.forEach((x) => {
        // x.counter.outlet = null;
        x.counter.outlet.counterGroups = [];
        x.counter.outlet.counters = [];

        x.counter.counterToCounterGroupAdjustmentLogs.unshift({
          active: x.active,
          counterGroup: tempCounterGroup,
          counterGroupId: x.counterGroupId,
          counterId: x.counterId,
          createdAt: x.createdAt,
          createdBy: x.createdBy,
          id: x.id,
          isActive: x.isActive,
          lastDateTime: x.lastDateTime,
          recordID: x.recordID,
          startDateTime: x.startDateTime,
          updatedAt: x.updatedAt,
          updatedBy: x.updatedBy
        });
        y.counters.push(x.counter);
      })
    });
    state.counterGroups = counterGroups.results;
    // console.log("counterGroups");
    // console.log(counterGroups);
  },

  // We dont use this because of can't find a consistent algotithm to alter state.counters with new changes
  POST_counterGroup: (state, counterGroup) => {
    // console.log("Check counterGroup Post 1");
    // console.log(counterGroup);

    counterGroup.walkinServiceTypes = [];
    counterGroup.counters = [];
    counterGroup.outlet.counters = [];

    var tempCounterGroup = {
      active: counterGroup.active,
      counterToCounterGroupAdjustmentLogs: [],
      counterGroupToWalkinServiceTypeAdjustmentLogs: [],
      createdAt: counterGroup.createdAt,
      createdBy: counterGroup.createdBy,
      description: counterGroup.description,
      id: counterGroup.id,
      imageUrl: counterGroup.imageUrl,
      isActive: counterGroup.isActive,
      lastDate: counterGroup.lastDate,
      lastTime: counterGroup.lastTime,
      name: counterGroup.name,
      outlet: null,
      outletId: counterGroup.outletId,
      recordID: counterGroup.recordID,
      startDate: counterGroup.startDate,
      startTime: counterGroup.startTime,
      updatedAt: counterGroup.updatedAt,
      updatedBy: counterGroup.updatedBy,
    };

    counterGroup.counterGroupToWalkinServiceTypeAdjustmentLogs.forEach((x) => { counterGroup.walkinServiceTypes.push(x.walkinServiceType); });
    counterGroup.counterToCounterGroupAdjustmentLogs.forEach((x) => {
      x.counter.outlet.counters = [];
      x.counter.outlet.counterGroups = [];
      x.counter.outlet.organization = null;
      x.counter.counterToCounterGroupAdjustmentLogs = [];
      x.counter.counterToCounterGroupAdjustmentLogs = state.counters.find((y) => y.id == x.counterId).counterToCounterGroupAdjustmentLogs;
      x.counter.counterToCounterGroupAdjustmentLogs.unshift({
        active: x.active,
        counterGroup: tempCounterGroup,
        counterGroupId: tempCounterGroup.id,
        counterId: x.counterId,
        createdAt: x.createdAt,
        createdBy: x.createdBy,
        id: x.id,
        isActive: x.isActive,
        lastDateTime: x.lastDateTime,
        recordID: x.recordID,
        startDateTime: x.startDateTime,
        updatedAt: x.updatedAt,
        updatedBy: x.updatedBy
      });
      counterGroup.counters.push(x.counter);
      // state.counters.find((y)=>y.id==x.counterId).counterToCounterGroupAdjustmentLogs.unshift(x.counter.counterToCounterGroupAdjustmentLogs[0]);
    });
    state.counterGroups.unshift(counterGroup);
    state.counterGroup = counterGroup;
    // console.log("Check counterGroup Post 2");
    // console.log(state.counterGroup);

  },

  // We dont use this because of can't find a consistent algotithm to alter state.counters with new changes
  PUT_counterGroup: (state, counterGroup) => {
    // console.log("Check counterGroup Put 1");
    // console.log(counterGroup);
    var tempCounterGroup = {
      active: counterGroup.active,
      counterToCounterGroupAdjustmentLogs: [],
      counterGroupToWalkinServiceTypeAdjustmentLogs: [],
      createdAt: counterGroup.createdAt,
      createdBy: counterGroup.createdBy,
      description: counterGroup.description,
      id: counterGroup.id,
      imageUrl: counterGroup.imageUrl,
      isActive: counterGroup.isActive,
      lastDate: counterGroup.lastDate,
      lastTime: counterGroup.lastTime,
      name: counterGroup.name,
      outlet: null,
      outletId: counterGroup.outletId,
      recordID: counterGroup.recordID,
      startDate: counterGroup.startDate,
      startTime: counterGroup.startTime,
      updatedAt: counterGroup.updatedAt,
      updatedBy: counterGroup.updatedBy,
    };

    counterGroup.walkinServiceTypes = [];
    counterGroup.counters = [];
    counterGroup.outlet.counters = [];

    counterGroup.counterGroupToWalkinServiceTypeAdjustmentLogs.forEach((x) => { counterGroup.walkinServiceTypes.push(x.walkinServiceType); });
    counterGroup.counterToCounterGroupAdjustmentLogs.forEach((x) => {
      x.counter.outlet.counters = [];
      x.counter.outlet.counterGroups = [];
      x.counter.outlet.organization = null;
      x.counter.counterToCounterGroupAdjustmentLogs = [];
      state.counters.find((y) => y.id == x.counterId).counterToCounterGroupAdjustmentLogs.splice(0, state.counters.find((y) => y.id == x.counterId).counterToCounterGroupAdjustmentLogs.length);
      var stateCounterLogs = state.counters.find((y) => y.id == x.counterId).counterToCounterGroupAdjustmentLogs;
      x.counter.counterToCounterGroupAdjustmentLogs.forEach((y) => { stateCounterLogs.unshift(y) });

      // if(x.id==0){
      x.counter.counterToCounterGroupAdjustmentLogs.unshift({
        active: x.active,
        counterGroup: tempCounterGroup,
        counterGroupId: tempCounterGroup.id,
        counterId: x.counterId,
        createdAt: x.createdAt,
        createdBy: x.createdBy,
        id: x.id,
        isActive: x.isActive,
        lastDateTime: x.lastDateTime,
        recordID: x.recordID,
        startDateTime: x.startDateTime,
        updatedAt: x.updatedAt,
        updatedBy: x.updatedBy
      });
      state.counters.find((y) => y.id == x.counterId).counterToCounterGroupAdjustmentLogs.unshift(x.counter.counterToCounterGroupAdjustmentLogs[0]);
      // }}
      counterGroup.counters.push(x.counter);
    });
    state.counterGroups[state.counterGroups.findIndex((x) => { return (x.id == counterGroup.id); })] = counterGroup;
    state.counterGroup = counterGroup;
    // console.log("Check counterGroup Put 2");
    // console.log(state.counterGroup);
  },

  SET_counters: (state, counters) => {
    counters.forEach((x) => { x.outlet.counterGroups = []; x.outlet.counters = []; x.counterToCounterGroupAdjustmentLogs.forEach((y) => { y.counterGroup.counterToCounterGroupAdjustmentLogs = []; y.counterGroup.outlet = null; }) });
    state.counters = counters;
  },
  SET_organizations: (state, organizations) => {
    state.organizations = organizations;
  },
  SET_staffPositions: (state, staffPositions) => {
    state.staffPositions = staffPositions;
  },
  SET_walkinServiceTypes: (state, walkinServiceTypes) => {
    state.walkinServiceTypes = walkinServiceTypes;
  },
  SET_outletsOnly: (state, data) => {
    state.outletsOnly = data;
  },
};
