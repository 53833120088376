export default {
  POST_receipt: (state, receipt) => {
    // console.log(receipt);
    state.receipts.unshift(receipt);
    state.receipt = receipt;
  },
  SET_receiptTrack: (state, receipt) => {
    state.receipt = receipt;
  },
  SET_receipts: (state, receipts) => {
    state.receipts = receipts.results;
    state.totalReceipts = receipts.totalCount;
  },
  POST_newProgress: (state, { completed, status }) => {
    for (var i = 0; i < state.receipts.length; i++) {
      for (var j = 0; j < state.receipts[i].deviceDetails.length; j++) {
        if (state.receipts[i].deviceDetails[j].id == status.deviceId) {
          state.receipts[i].receiptServiceStatus = completed;
        }
      }
    }
  },
  PUT_completionImage: (state, { deviceId, result }) => {
    state.completionImage = result;
    // console.log(deviceId);
    // state.receipts.forEach(x => console.log(x.deviceDetails));
    state.receipts.forEach(x => { var theDevice = x.deviceDetails.findIndex(y => y.id == deviceId); console.log(theDevice);theDevice>=0 ? (x.deviceDetails[theDevice].completionImage = result) : null; });
    
    state.receipts.forEach(x => console.log(x.deviceDetails));
  }
};
