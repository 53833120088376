export default {
  user: (state, user) => {
    state.user = user;
  },
  SET_user: (state, data) => {
    //LocalStorage always store string
    localStorage.setItem('token', data.token);
    localStorage.setItem('userId', data.userDetail.id);
    localStorage.setItem('userName', data.userDetail.userName);
    localStorage.setItem('isStaff', data.userDetail.isStaff);
    localStorage.setItem('firstName', data.userDetail.firstName);
    localStorage.setItem('lastName', data.userDetail.lastName);
    localStorage.setItem(
      'staffPosition',
      data.userDetail.staffDetail.staffPosition.name
    );
    localStorage.setItem(
      'counterId',
      data.userDetail.staffDetail.hasCounter
        ? data.userDetail.staffDetail.counterDetail.id
        : null
    );
    localStorage.setItem(
      'counterGroupId',
      data.userDetail.staffDetail.hasCounter
        ? data.userDetail.staffDetail.counterDetail.counterGroupDetail.id
        : (data.userDetail.staffDetail.staffPosition.name ==
            'Branch Administrator' ||data.userDetail.staffDetail.staffPosition.name ==
            'Receptionist')
        ? -1
        : (data.userDetail.staffDetail.staffPosition.name == "Kerawang Administrator" || data.userDetail.staffDetail.staffPosition.name == "HQ Administrator")?-2:null
    );
    state.token = localStorage.getItem('token');
    state.user = {
      userId: parseInt(localStorage.getItem('userId')),
      userName: localStorage.getItem('userName'),
      isStaff: localStorage.getItem('isStaff'),
      firstName: localStorage.getItem('firstName'),
      lastName: localStorage.getItem('lastName'),
      staffPosition: localStorage.getItem('staffPosition'),
      counterId: parseInt(localStorage.getItem('counterId')),
      counterGroupId: parseInt(localStorage.getItem('counterGroupId')),
    };
    state.status = 'success';
  },
};
