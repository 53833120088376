import Client from "./Clients/AxiosClient";
const resource = "/api/ReceiptDetail";

export default {
  getReceipts(lazyParams, searchFilterbyCategory,searchById,searchByString,outletId) {
    return Client.get(
      `${resource}/${localStorage.getItem("staffPosition")}/${localStorage.getItem("userId")}/${searchFilterbyCategory}/${outletId}`,
      {
        params: {
          skipCount: lazyParams.first,
          takeCount: lazyParams.rows,
          searchById:searchById,
          searchByString: searchByString,
        },
      }
    );
  },
  getReceiptTrack(receiptCode) {
    return Client.get(`${resource}/ReceiptCodeTracking/${receiptCode}`);
  },
  create(receipt) {
    return Client.post(`${resource}`, receipt);
  },
  putCompletionImage(deviceId,completionImage){
    return Client.put(`${resource}/CompletionImage`,{deviceId:deviceId,completionImage:completionImage});
  }
};
