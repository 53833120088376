import Client from "./Clients/AxiosClient";
// const resource = "/api/Device"; //Uncomment this id

export default {
  setTechnicalServiceStatusTimelineNewProgress(status) {
    return Client.post(
      `/api/TechnicalServiceStatusTimeline/NewProgress`,
      status
    );
  },
  getServices(deviceCategoryId) {
    // console.log(deviceCategoryId);
    return Client.get(`/api/TechnicalServiceType/DeviceCategoryId/${deviceCategoryId}`);
  },
  getSpareParts(deviceCategoryId) {
    return Client.get(`/api/SparePartType/DeviceCategoryId/${deviceCategoryId}`);
  },
  getBrandOptions(deviceCategoryId) {
    return Client.get(`/api/DeviceBrand/DeviceCategoryId/${deviceCategoryId}`);
  },
  getDefectOptions(deviceCategoryId) {
    return Client.get(`/api/Defection/DeviceCategoryId/${deviceCategoryId}`);
  },
  getDeviceTypes(deviceCategoryId) {
    return Client.get(`/api/DeviceType/DeviceCategoryId/${deviceCategoryId}`);
  },

  getServiceCategories() {
    return Client.get(`/api/TechnicalServiceTypeCategory`);
  },
  getSparePartCategories() {
    return Client.get(`/api/SparePartTypeCategory`);
  },
  getColorOptions() {
    return Client.get(`/api/Color`);
  },
  getServiceStatusTechs() {
    return Client.get(`/api/StatusCategoryList`);
  },
  getDeviceCategories() {
    return Client.get(`/api/DeviceCategory`);
  },
};
