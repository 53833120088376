import Client from "./Clients/AxiosClient";
const resource = "/api/SystemTeeStatistics";

export default {
    getDashboardStatisticsBasic(payload) {
        if (payload.date[0] != null) {
            var dateTempStart = new Date(payload.date[0]);
            dateTempStart.setHours(payload.date[0].getHours() + 8);
        }
        if (payload.date[1] != null) {
            var dateTempEnd = new Date(payload.date[1]);
            dateTempEnd.setDate(payload.date[1].getDate() + 1);
            dateTempEnd.setHours(payload.date[1].getHours() + 8);
        }
        return Client.get(`${resource}/${payload.staffId}/${payload.staffPositionId}/${payload.outletId}`,
            {
                params: {
                    dateInitial: dateTempStart,
                    dateEnd: dateTempEnd,

                },
            });
    },
}