import userMutations from "./mutations.js";
import userActions from "./actions.js";
import userGetters from "./getters.js";

export default {
  namespaced: true, // put true if want enable modules
  state() {
    return {
      user: {
        //LocalStorage always store string
        userId: parseInt(localStorage.getItem("userId")) || null,
        userName: localStorage.getItem("userName") || null,
        isStaff: localStorage.getItem("isStaff") || null,
        firstName: localStorage.getItem("firstName") || null,
        lastName: localStorage.getItem("lastName") || null,
        staffPosition: localStorage.getItem("staffPosition") || null,
        counterId: parseInt(localStorage.getItem("counterId")) || null,
        counterGroupId:
          parseInt(localStorage.getItem("counterGroupId")) || null,
      },
    };
  },
  mutations: userMutations,
  actions: userActions,
  getters: userGetters,
};
