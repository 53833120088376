<template>
  <div>
    <Toolbar class="p-mb-4">
      <template v-slot:left>
        <b
          >{{ staff.primeUser.userName }}, <br />
          ({{ staff.outlet.name }} - {{ staffPosition }})</b
        >
      </template>
      <template v-slot:right>
        <div
          class="spinner-border text-primary"
          v-if="loadingDashboardData"
        ></div>
        <div v-if="searchFilterbyCategory == 2">
          <Calendar
            id="dateEnd"
            dateFormat="dd/mm/yy"
            placeholder="Pick Date"
            selectionMode="range"
            v-model="dateModel"
            :maxDate="new Date()"
            :class="{
              'p-invalid': !date[0],
            }"
          />
        </div>
        <div
          style="
            justify-content: center;
            text-align: center;
            margin-left: 2vw;
            margin-right: 2vw;
          "
        >
          <Dropdown
            id="searchFilterbyCategory"
            v-model="searchFilterbyCategory"
            :options="searchFilterbyCategoryOptions"
            optionLabel="Choose category"
            placeholder="Select a category"
            style="width: 100px"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>
                  {{ searchFilterbyCategoryName(slotProps.value) }}
                </div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>
                  {{ searchFilterbyCategoryName(slotProps.option) }}
                </div>
              </div>
            </template>
          </Dropdown>
          <div
            class="spinner-border text-primary"
            v-if="isSuperAdmin && loadingOutlets"
          ></div>
          <Dropdown
            id="searchFilterbyOutlet"
            v-model="searchFilterbyOutlet"
            :options="searchFilterbyOutletOptions"
            optionLabel="Choose outlet"
            placeholder="Select a outlet"
            style="width: 175px"
            v-if="isSuperAdmin && !loadingOutlets"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>
                  {{ slotProps.value.name }}
                </div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>
                  {{ slotProps.option.name }}
                </div>
              </div>
            </template>
          </Dropdown>
          <div v-if="this.searchFilterbyOutlet.id===0">
            {{ count-1 }}/{{searchFilterbyOutletOptions.length-1}}
          </div>
        </div>
        <Button
          id="print"
          style="width: 75px"
          label="Print"
          @click="printReport()"
        />
      </template>
    </Toolbar>
    <div class="p-grid p-fluid dashboard">
      <!-- <div class="p-col-12 p-lg-4">
		<div class="card summary">
			<span class="title">Employee</span>
			<span class="detail">Number of <span>Employees</span></span>
			<span class="count visitors">25</span>
		</div>
	</div>
	<div class="p-col-12 p-lg-4">
		<div class="card summary">
			<span class="title">Customer</span>
			<span class="detail">Number of Customers (Today)</span>
			<span class="count purchases">2</span>
		</div>
	</div>
	<div class="p-col-12 p-lg-4">
		<div class="card summary">
			<span class="title">Sales</span>
			<span class="detail">Total Repairing Sales (Today)</span>
			<span class="count revenue">RM1,000</span>
		</div>
	</div> -->

      <div class="p-col-12 p-md-6 p-xl-3">
        <div class="highlight-box">
          <div
            class="initials"
            style="background-color: #007be5; color: #00448f"
          >
            <span>A</span>
          </div>
          <div class="highlight-details">
            <!-- <i class="pi pi-search"></i> -->
            <span>Total Device Handled </span>
            <span class="count">{{
              displayNumber.totalDeviceAll
            }}</span>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-xl-3">
        <div class="highlight-box">
          <div
            class="initials"
            style="background-color: #c08de3; color: #9240c2"
          >
            <span>B</span>
          </div>
          <div class="highlight-details">
            <span>Total Device No Action Yet</span>
            <span class="count">{{
              displayNumber.totalDeviceNoActionYet
            }}</span>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-xl-3">
        <div class="highlight-box">
          <div
            class="initials"
            style="background-color: #f9c851; color: #b58c2b"
          >
            <span>C</span>
          </div>
          <div class="highlight-details">
            <span>Total Device In Progress</span>
            <span class="count">{{
              displayNumber.totalDeviceInProgress
            }}</span>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-xl-3">
        <div class="highlight-box">
          <div
            class="initials"
            style="background-color: #20d077; color: #038d4a"
          >
            <span>D</span>
          </div>
          <div class="highlight-details">
            <span>Total Device Completed</span>
            <span class="count">{{
              displayNumber.totalDeviceCompleted
            }}</span>
          </div>
        </div>
      </div>

      <div class="p-col-12 p-md-6 p-xl-3">
        <div class="highlight-box">
          <div
            class="initials"
            style="background-color: #007be5; color: #00448f"
          >
            <span>E</span>
          </div>
          <div class="highlight-details">
            <!-- <i class="pi pi-search"></i> -->
            <span>Waiting for Specialist </span>
            <span class="count">{{
              displayNumber.totalDeviceWaitSpecialist
            }}</span>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-xl-3">
        <div class="highlight-box">
          <div
            class="initials"
            style="background-color: #c08de3; color: #9240c2"
          >
            <span>F</span>
          </div>
          <div class="highlight-details">
            <span>Waiting for Spare-part</span>
            <span class="count">{{
              displayNumber.totalDeviceWaitSparePart
            }}</span>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-xl-3">
        <div class="highlight-box">
          <div
            class="initials"
            style="background-color: #f9c851; color: #b58c2b"
          >
            <span>G</span>
          </div>
          <div class="highlight-details">
            <span>Waiting for Customer</span>
            <span class="count">{{
              displayNumber.totalDeviceWaitCustomer
            }}</span>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-xl-3">
        <div class="highlight-box">
          <div
            class="initials"
            style="background-color: #20d077; color: #038d4a"
          >
            <span>H</span>
          </div>
          <div class="highlight-details">
            <span>Completion Rate</span>
            <span class="count">{{
              Math.round((displayNumber.totalDeviceCompleted/displayNumber.totalDeviceAll) * 100) / 100
            }}</span>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-xl-6" style="height: 100%">
        <div class="highlight-box">
          <div
            class="initials"
            style="background-color: #ef6262; color: #a83d3b"
          >
            <span>I</span>
          </div>
          <div class="highlight-details">
            <!-- <i class="pi pi-search"></i> -->
            <span>T.D. In Progress </span> <br />
            <small>(Excl. Wait For Cust.)</small>
            <span class="count">{{
              displayNumber.totalDeviceInProgressExcludeWFC
            }}</span>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-xl-6">
        <div class="highlight-box">
          <div
            class="initials"
            style="background-color: #ef6262; color: #a83d3b"
          >
            <span>J</span>
          </div>
          <div class="highlight-details">
            <!-- <i class="pi pi-search"></i> -->
            <span>No Action<br /><small>(>=48 Hours)</small> </span>
            <span class="count" style="display: fixed">{{
              displayNumber.totalDevice2DaysNoActionYet
            }}</span>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-xl-6">
        <div class="highlight-box">
          <div
            class="initials"
            style="background-color: #ef6262; color: #a83d3b"
          >
            <span>K</span>
          </div>
          <div class="highlight-details">
            <!-- <i class="pi pi-search"></i> -->
            <span>In Progress<br /><small>(>=14 Days)</small> </span>
            <span class="count">{{
              displayNumber.totalDevice2WeeksInProgress
            }}</span>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-xl-6">
        <div class="highlight-box">
          <div
            class="initials"
            style="background-color: #ef6262; color: #a83d3b"
          >
            <span>L</span>
          </div>
          <div class="highlight-details">
            <!-- <i class="pi pi-search"></i> -->
            <span>In Progress </span> <br />
            <small>(>=14 Days) (Excl. W.F.C.)</small>
            <span class="count">{{
              displayNumber.totalDevice2WeeksInProgressExcludeWFC
            }}</span>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-xl-6">
        <div class="highlight-box">
          <div
            class="initials"
            style="background-color: #ef6262; color: #a83d3b"
          >
            <span>M</span>
          </div>
          <div class="highlight-details">
            <!-- <i class="pi pi-search"></i> -->
            <span>In Progress<br /><small>(>=21 Days)</small> </span>
            <span class="count">{{
              displayNumber.totalDevice3WeeksInProgress
            }}</span>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-xl-6">
        <div class="highlight-box">
          <div
            class="initials"
            style="background-color: #ef6262; color: #a83d3b"
          >
            <span>N</span>
          </div>
          <div class="highlight-details">
            <!-- <i class="pi pi-search"></i> -->
            <span>In Progress </span> <br />
            <small>(>=21 Days) (Excl. W.F.C.)</small>
            <span class="count">{{
              displayNumber.totalDevice3WeeksInProgressExcludeWFC
            }}</span>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-xl-6">
        <div class="highlight-box">
          <div
            class="initials"
            style="background-color: #ef6262; color: #a83d3b"
          >
            <span>O</span>
          </div>
          <div class="highlight-details">
            <!-- <i class="pi pi-search"></i> -->
            <span>In Progress<br /><small>(>=28 Days)</small> </span>
            <span class="count">{{
              displayNumber.totalDevice4WeeksInProgress
            }}</span>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-xl-6">
        <div class="highlight-box">
          <div
            class="initials"
            style="background-color: #ef6262; color: #a83d3b"
          >
            <span>P</span>
          </div>
          <div class="highlight-details">
            <!-- <i class="pi pi-search"></i> -->
            <span>In Progress </span> <br />
            <small>(>=28 Days) (Excl. W.F.C.)</small>
            <span class="count">{{
              displayNumber.totalDevice4WeeksInProgressExcludeWFC
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import Toolbar from 'primevue/toolbar';
export default {
  components: { Calendar, Dropdown, Toolbar },
  data() {
    return {
      dateModel: null,
      date: { 0: null, 1: null },
      loadingDashboardData: false,
      activated: false,
      searchFilterbyCategory: 1,
      searchFilterbyCategoryOptions: [1, 2],
      searchFilterbyOutlet: { id: 0, name: 'All Outlets' },
      loadingOutlets: true,
      count:0,

      displayNumber:{
        completionRate:0,
        totalDevice2DaysNoActionYet: 0,
        totalDevice2WeeksInProgress: 0,
        totalDevice2WeeksInProgressExcludeWFC: 0,
        totalDevice3WeeksInProgress: 0,
        totalDevice3WeeksInProgressExcludeWFC: 0,
        totalDevice4WeeksInProgress: 0,
        totalDevice4WeeksInProgressExcludeWFC: 0,
        totalDeviceAll: 0,
        totalDeviceCompleted: 0,
        totalDeviceInProgress: 0,
        totalDeviceInProgressExcludeWFC: 0,
        totalDeviceNoActionYet: 0,
        totalDeviceWaitCustomer: 0,
        totalDeviceWaitSparePart: 0,
        totalDeviceWaitSpecialist: 0,
      }
    };
  },

  mounted() {
    this.loadingDashboardData = true;
    if (this.isSuperAdmin) {
      this.$store
        .dispatch('staff/getOutletsOnly', {
          organizationId: this.staff.outlet.organizationId,
        })
        .then(() => {
          this.loadingOutlets = false;
          this.getData();
        });
    }else{
      this.getData();
    }
  },
  watch: {
    searchFilterbyCategory: function (val) {
      if (val) {
        // console.log(this.date);
        this.loadingDashboardData = true;
        if (val == 1) {
          this.dateModel = null;
          this.activated = false;
          this.date = { 0: null, 1: null };
        } else if (val == 2) {
          this.dateModel = new Date();
          this.activated = false;
          this.date = { 0: new Date(), 1: null };
        }
        this.getData();
      }
    },
    dateModel: function (val) {
      this.loadingDashboardData = true;
      if (val) {
        if (this.activated) {
          this.date = val;
        }
        this.activated = true;
        this.getData();
      }
    },
    searchFilterbyOutlet: function (val) {
      this.loadingDashboardData = true;
      if (val) {
        this.getData();
      }
    },
  },
  computed: {
    dashboardStatisticsBasic() {
      let dashboardStatisticsBasic =
        this.$store.getters['statistics/getDashboardStatisticsBasic'];
      return dashboardStatisticsBasic.data.result;
    },
    staff() {
      let staff = this.$store.getters['logged/getStaff'];
      return staff.data.result;
    },
    staffPosition() {
      return localStorage.getItem('staffPosition');
    },
    outlets() {
      let outlets = this.$store.getters['staff/outletsOnly'];
      return outlets;
    },
    isSuperAdmin() {
      return (
        this.staff.staffPosition.name == 'HQ Administrator' ||
        this.staff.staffPosition.name == 'Kerawang Administrator'
      );
    },
    searchFilterbyOutletOptions() {
      if(Array.isArray(this.outlets)){
        return [{ id: 0, name: 'All Outlets' }, ...this.outlets];

      }else{
        return [{ id: 0, name: 'All Outlets' }];
      }
    },
  },
  methods: {
    async getData() {

      
      this.displayNumber.completionRate=0;
                this.displayNumber.totalDevice2DaysNoActionYet=0;
                this.displayNumber.totalDevice2WeeksInProgress=0;
                this.displayNumber.totalDevice2WeeksInProgressExcludeWFC=0;
                this.displayNumber.totalDevice3WeeksInProgress=0;
                this.displayNumber.totalDevice3WeeksInProgressExcludeWFC=0;
                this.displayNumber.totalDevice4WeeksInProgress=0;
                this.displayNumber.totalDevice4WeeksInProgressExcludeWFC=0;
                this.displayNumber.totalDeviceAll=0;
                this.displayNumber.totalDeviceCompleted=0;
                this.displayNumber.totalDeviceInProgress=0;
                this.displayNumber.totalDeviceInProgressExcludeWFC=0;
                this.displayNumber.totalDeviceNoActionYet=0;
                this.displayNumber.totalDeviceWaitCustomer=0;
                this.displayNumber.totalDeviceWaitSparePart=0;
                this.displayNumber.totalDeviceWaitSpecialist=0;

      if(this.searchFilterbyOutlet.id===0 && this.isSuperAdmin){

        let current=1;

        do{

          let array=[];
          for(let i=current;i<(current+10) &&i<(this.searchFilterbyOutletOptions.length);i++){
            array.push(this.searchFilterbyOutletOptions[i]);

          }
          current+=array.length;


          
          await Promise.all(array.map(async (value) => await 
            this.$store
              .dispatch('statistics/getDashboardStatisticsBasic', {
                staffId: this.staff.id,
                staffPositionId: this.staff.staffPositionId,
                date: this.date,
                outletId: value.id,
              }))
          ).then(async (response) => {
            response.forEach((data)=>{

                this.displayNumber.completionRate+=data.data.result.completionRate;
                this.displayNumber.totalDevice2DaysNoActionYet+=data.data.result.totalDevice2DaysNoActionYet;
                this.displayNumber.totalDevice2WeeksInProgress+=data.data.result.totalDevice2WeeksInProgress;
                this.displayNumber.totalDevice2WeeksInProgressExcludeWFC+=data.data.result.totalDevice2WeeksInProgressExcludeWFC;
                this.displayNumber.totalDevice3WeeksInProgress+=data.data.result.totalDevice3WeeksInProgress;
                this.displayNumber.totalDevice3WeeksInProgressExcludeWFC+=data.data.result.totalDevice3WeeksInProgressExcludeWFC;
                
                this.displayNumber.totalDevice4WeeksInProgress+=data.data.result.totalDevice4WeeksInProgress;
                this.displayNumber.totalDevice4WeeksInProgressExcludeWFC+=data.data.result.totalDevice4WeeksInProgressExcludeWFC;
                this.displayNumber.totalDeviceAll+=data.data.result.totalDeviceAll;
                this.displayNumber.totalDeviceCompleted+=data.data.result.totalDeviceCompleted;
                this.displayNumber.totalDeviceInProgress+=data.data.result.totalDeviceInProgress;
                this.displayNumber.totalDeviceInProgressExcludeWFC+=data.data.result.totalDeviceInProgressExcludeWFC;
                this.displayNumber.totalDeviceNoActionYet+=data.data.result.totalDeviceNoActionYet;
                this.displayNumber.totalDeviceWaitCustomer+=data.data.result.totalDeviceWaitCustomer;
                this.displayNumber.totalDeviceWaitSparePart+=data.data.result.totalDeviceWaitSparePart;
                this.displayNumber.totalDeviceWaitSpecialist+=data.data.result.totalDeviceWaitSpecialist;

                this.loadingDashboardData = false

                this.count=current;
            });
          });

        }while(this.count<(this.searchFilterbyOutletOptions.length))

      }else{
        await this.$store
          .dispatch('statistics/getDashboardStatisticsBasic', {
            staffId: this.staff.id,
            staffPositionId: this.staff.staffPositionId,
            date: this.date,
            outletId: this.searchFilterbyOutlet.id,
          })
          .then((data) => {
                this.displayNumber.completionRate=data.data.result.completionRate;
                this.displayNumber.totalDevice2DaysNoActionYet=data.data.result.totalDevice2DaysNoActionYet;
                this.displayNumber.totalDevice2WeeksInProgress=data.data.result.totalDevice2WeeksInProgress;
                this.displayNumber.totalDevice2WeeksInProgressExcludeWFC=data.data.result.totalDevice2WeeksInProgressExcludeWFC;
                this.displayNumber.totalDevice3WeeksInProgress=data.data.result.totalDevice3WeeksInProgress;
                this.displayNumber.totalDevice3WeeksInProgressExcludeWFC=data.data.result.totalDevice3WeeksInProgressExcludeWFC;
                this.displayNumber.totalDevice4WeeksInProgress=data.data.result.totalDevice4WeeksInProgress;
                this.displayNumber.totalDevice4WeeksInProgressExcludeWFC=data.data.result.totalDevice4WeeksInProgressExcludeWFC;
                this.displayNumber.totalDeviceAll=data.data.result.totalDeviceAll;
                this.displayNumber.totalDeviceCompleted=data.data.result.totalDeviceCompleted;
                this.displayNumber.totalDeviceInProgress=data.data.result.totalDeviceInProgress;
                this.displayNumber.totalDeviceInProgressExcludeWFC=data.data.result.totalDeviceInProgressExcludeWFC;
                this.displayNumber.totalDeviceNoActionYet=data.data.result.totalDeviceNoActionYet;
                this.displayNumber.totalDeviceWaitCustomer=data.data.result.totalDeviceWaitCustomer;
                this.displayNumber.totalDeviceWaitSparePart=data.data.result.totalDeviceWaitSparePart;
                this.displayNumber.totalDeviceWaitSpecialist=data.data.result.totalDeviceWaitSpecialist;
                this.loadingDashboardData = false
              });
      }
    },
    setDate() {
      this.date = null;
    },

    searchFilterbyCategoryName(value) {
      let filterString = '';
      switch (value) {
        case 1:
          filterString = 'All Date';
          break;
        case 2:
          filterString = 'By Date';
          break;
        default:
          filterString = '-';
      }
      return filterString;
    },
    async printReport() {
      try {
        window.print();
      } catch (err) {
        alert(
          'Sorry, this browser does not support this feature. Error: ' +
            err.message,
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
