//index.js
import Repository from '../../../repositories/RepositoryFactory';
const StaffRepository = Repository.get('staffs');
export default {
  postStaff: async ({ commit }, { staff, regAuth }) => {
    var response = await StaffRepository.createStaff(staff, regAuth);
    commit('POST_staff', response.data.result);
  },
  updateStaff: async ({ commit }, { staff }) => {
    var response = await StaffRepository.updateStaff(staff);
    commit('PUT_staff', response.data.result);
  },

  getStaffs: async (
    { commit },
    { lazyParams, searchFilterbyOutlet, searchFilterbyContact },
  ) => {
    var response = await StaffRepository.getStaffs(
      lazyParams,
      searchFilterbyOutlet,
      searchFilterbyContact,
    );
    commit('SET_staffs', response.data.result);
  },

  getCounterGroups: async ({ commit }, { lazyParams }) => {
    var response = await StaffRepository.getCounterGroups(lazyParams);
    commit('SET_counterGroups', response.data.result);
  },
  postCounterGroup: async ({ commit }, { counterGroup }) => {
    var response = await StaffRepository.createCounterGroup(counterGroup);
    // var response2 = await StaffRepository.getCounters();
    // commit("SET_counters", response2.data.result);
    // commit("SET_counterGroups", response.data.result);
    // We dont use this because of can't find a consistent algotithm to alter state.counters with new changes
    commit('POST_counterGroup', response.data.result);
  },
  updateCounterGroup: async (
    { commit },
    { counterGroup, lazyParamsCounterGroups },
  ) => {
    var response = await StaffRepository.updateCounterGroup(
      counterGroup,
      lazyParamsCounterGroups,
    );
    // var response2 = await StaffRepository.getCounters();
    // commit("SET_counters", response2.data.result);
    commit('SET_counterGroups', response.data.result);
    // We dont use this because of can't find a consistent algotithm to alter state.counters with new changes
    // commit("PUT_counterGroup", response.data.result);
  },

  getOutlets: async ({ commit }) => {
    var response = await StaffRepository.getOutlets();
    commit('SET_outlets', response.data.result);
  },
  postOutlet: async ({ commit }, { outlet }) => {
    var response = await StaffRepository.createOutlet(outlet);
    commit('POST_outlet', response.data.result);
  },
  updateOutlet: async ({ commit }, { outlet, lazyParamsCounterGroups }) => {
    var response = await StaffRepository.updateOutlet(outlet);
    // var response2 = await StaffRepository.getCounters();
    var response3 = await StaffRepository.getCounterGroups(
      lazyParamsCounterGroups,
    );
    commit('PUT_outlet', response.data.result);
    // commit("SET_counters", response2.data.result);
    commit('SET_counterGroups', response3.data.result);
  },

  getCounters: async ({ commit }, payload) => {
    var response = await StaffRepository.getCounters(payload);
    commit('SET_counters', response.data.result);
  },
  getOrganizations: async ({ commit }) => {
    var response = await StaffRepository.getOrganizations();
    commit('SET_organizations', response.data.result);
  },
  getStaffPositions: async ({ commit }) => {
    var response = await StaffRepository.getStaffPositions();
    commit('SET_staffPositions', response.data.result);
  },
  getWalkinServiceTypes: async ({ commit }) => {
    var response = await StaffRepository.getWalkinServiceTypes();
    commit('SET_walkinServiceTypes', response.data.result);
  },
  getOutletsOnly: async ({ commit }, payload) => {
    var response = await StaffRepository.getOutletsOnly(payload);
    commit('SET_outletsOnly', response.data.result);
  },
};
