export default {
  receipt: (state) => {
    return state.receipt;
  },
  receipts: (state) => {
    return state.receipts;
  },
  totalReceipts: (state) => {
    return state.totalReceipts;
  },
  completionImage: (state) => {
    return state.completionImage;
  },
};
