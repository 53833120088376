//index.js
import Repository from "../../../repositories/RepositoryFactory";
const ReceiptRepository = Repository.get("receipts");
const DeviceRepository = Repository.get("devices");

export default {
  postReceipt: async ({ commit }, { receipt }) => {
    // console.log(receipt);
    //Please note that if the datatype of the input doesn't match the API and database, it will send null value
    var response = await ReceiptRepository.create(receipt);
    commit("POST_receipt", response.data.result);
  },
  getReceiptTrack: async ({ commit }, { receiptCode }) => {
    var response = await ReceiptRepository.getReceiptTrack(receiptCode);
    commit("SET_receiptTrack", response.data.result);
  },
  getReceipts: async ({ commit }, { lazyParams, searchFilterbyCategory, searchById, searchByString, outletId }) => {
    var response = await ReceiptRepository.getReceipts(lazyParams,searchFilterbyCategory,searchById, searchByString, outletId);
    commit("SET_receipts", response.data.result);
  },
  putCompletionImage: async ({ commit }, { deviceId,completionImage }) => {
    var response = await ReceiptRepository.putCompletionImage(deviceId,completionImage);
    commit("PUT_completionImage", {deviceId:deviceId,result:response.data.result});
  },
  postNewProgress: async (
    { commit },
    { deviceId, name, category, type, newStatusStage, maxStatusStage, succeed }
  ) => {
    var status = {
      deviceId: deviceId,
      name: name,
      category: category,
      type: type,
      newStatusStage: newStatusStage,
      maxStatusStage: maxStatusStage,
      succeed: succeed
    };
    var response = await DeviceRepository.setTechnicalServiceStatusTimelineNewProgress(
      status
    );
    commit("POST_newProgress", {
      completed: response.data.result,
      status: status,
    });
  },
};
