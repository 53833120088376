//index.js
import Repository from "../../../repositories/RepositoryFactory";
const DeviceRepository = Repository.get("devices");
import axios from "axios";

export default {
  // getDevices: async ({ commit }) => {
  //   await axios
  //     .get("assets/layout/data/devices.json")
  //     .then(function(response) {
  //       commit("SET_devices", response.data.allCreated);
  //     })
  //     .catch((error) => console.log(error));
  // },
  getServices: async ({ commit }, {deviceCategoryId}) => {
    var response = await DeviceRepository.getServices(deviceCategoryId);
    commit("SET_serviceOptions", response.data.result);
  },
  getSpareParts: async ({ commit }, {deviceCategoryId}) => {
    var response = await DeviceRepository.getSpareParts(deviceCategoryId);
    commit("SET_sparePartOptions", response.data.result);
  },
  getBrandOptions: async ({ commit }, {deviceCategoryId}) => {
    var response = await DeviceRepository.getBrandOptions(deviceCategoryId);
    commit("SET_brandOptions", response.data.result);
  },
  getDefectOptions: async ({ commit }, {deviceCategoryId}) => {
    var response = await DeviceRepository.getDefectOptions(deviceCategoryId);
    commit("SET_defectOptions", response.data.result);
  },
  getDeviceTypes: async ({ commit }, {deviceCategoryId}) => {
    var response = await DeviceRepository.getDeviceTypes(deviceCategoryId);
    commit("SET_deviceTypes", response.data.result);
  },

  getServiceCategories: async ({ commit }) => {
    var response = await DeviceRepository.getServiceCategories();
    commit("SET_serviceCategoryOptions", response.data.result);
  },
  getSparePartCategories: async ({ commit }) => {
    var response = await DeviceRepository.getSparePartCategories();
    commit("SET_sparePartCategoryOptions", response.data.result);
  },
  getColorOptions: async ({ commit }) => {
    var response = await DeviceRepository.getColorOptions();
    commit("SET_colorOptions", response.data.result);
  },
  getServiceStatusTechs: async ({ commit }) => {
    var response = await DeviceRepository.getServiceStatusTechs();
    commit("SET_serviceStatusTechs", response.data.result);
  },
  getDeviceCategories: async ({ commit }) => {
    var response = await DeviceRepository.getDeviceCategories();
    commit("SET_deviceCategories", response.data.result);
  },
};
