import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "./components/Dashboard.vue";
const SystemQBaseUrl = process.env.VUE_APP_SYSTEM_QUEUE_URL;
const routes = [
  {
    path: "/",
    name: "home",
    component: function () {
      if (localStorage.getItem("token")) {
        return import('./AppContent.vue');
      } else {
        return import('./AppAuthentication.vue');
      }
    }
  },
  {
    path: '/appcontent',
    name: 'appcontent',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import('./AppContent.vue'),
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        props: true,
        meta: {
          requiresAuth: true
        },
        component: Dashboard,
      },
      {
        path: '/mycustomers',
        name: 'mycustomers',
        props: true,
        meta: {
          requiresAuth: true
        },
        component: () =>
          import('./pages/MyCustomers.vue'),
      },
      {
        path: '/tracking',
        name: 'tracking',
        props: true,
        meta: {
          requiresAuth: true
        },
        component: () =>
          import('./pages/Tracking.vue'),
      },

      {
        path: "/receptionist",
        name: "receptionist",
        meta: {
          requiresAuth: true
        },
        component: () => import("./components/RedirectPage.vue"),
        props: {
          linkUrl: `${SystemQBaseUrl}/imika/receptionist`,
          linkName: `Receptionist`,
        },
      },
      {
        path: "/queue",
        name: "queue",
        meta: {
          requiresAuth: true
        },
        component: () => import("./components/RedirectPage.vue"),
        props: {
          linkUrl: `${SystemQBaseUrl}/imika/queue`,
          linkName: `Queue`,
        },
      },

      {
        path: "/statistics",
        name: "statistics",
        meta: {
          requiresAuth: true
        },
        component: () => import("./components/RedirectPage.vue"),
        props: {
          linkUrl: `${SystemQBaseUrl}/imika/statistics`,
          linkName: `Statistics`,
        },
      },
      // {
      //   path: "/frontliner/group/:groupId/counter/:counterId",
      //   name: "frontliner",
      //   meta: {
      //     requiresAuth: true
      //   },
      //   component: () => import("./components/RedirectPage.vue"),
      //   props: (route) => ({
      //     linkUrl: `${SystemQBaseUrl}/imika/frontliner/group/${route.params.groupId}/counter/${route.params.counterId}`,
      //     linkName: `Frontliner`,
      //   })
      // },
      {
        path: "/frontliner",
        name: "frontliner",
        meta: {
          requiresAuth: true
        },
        component: () => import("./components/RedirectPage.vue"),
        props: {
          linkUrl: `${SystemQBaseUrl}/imika/frontliner`,
          linkName: `Frontliner`,
        },
      },
      {
        path: '/mystaffs',
        name: 'mystaffs',
        props: true,
        meta: {
          requiresAuth: true
        },
        component: () =>
          import('./pages/MyStaffs.vue'),
      },

    ]
  },
  {
    path: '/civilian/tracking',
    name: 'civilianTracking',
    props: true,
    meta: {
      requiresAuth: false
    },
    component: () =>
      import('./pages/Tracking.vue'),
  },
  {
    path: '/appauthentication',
    name: 'appauthentication',
    meta: {
      guest: true
    },
    component: () =>
      import('./AppAuthentication.vue'),
    children: [
      {
        path: '/welcome',
        name: 'welcome',
        props: true,
        meta: {
          guest: true
        },
        component: () =>
          import('./components/Welcome.vue'),
      },
      {
        path: '/login',
        name: 'login',
        props: true,
        meta: {
          guest: true
        },
        component: () =>
          import('./components/Login.vue'),
      },
      {
        path: '/register',
        name: 'register',
        props: true,
        meta: {
          guest: true
        },
        component: () =>
          import('./components/Register.vue'),
      },]
  }];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('token') == null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (localStorage.getItem('token') == null) {
      next()
    }
    else {
      next({
        path: '/dashboard',
        params: { nextUrl: to.fullPath }
      })
    }
  } else {
    next()
  }
});


export default router;
