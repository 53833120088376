import loggedMutations from "./mutations.js";
import loggedActions from "./actions.js";
import loggedGetters from "./getters.js";

export default {
  namespaced: true, // put true if want enable modules
  state() {
    return {
      staff: null,
    };
  },
  mutations: loggedMutations,
  actions: loggedActions,
  getters: loggedGetters,
};
