<template>
  <!-- <div id="app"> -->
  <router-view />
  <!-- </div> -->
</template>

<script>
export default {
  name: "App",
  components: {},
  mounted() {
    window.onpopstate = () => {
      // console.log(this.$route.path);
      if (
        localStorage.getItem("token") != null &&
        this.$route.path == "/login"
      ) {
        this.$router.push("/dashboard"); // redirect to home, for example
      }

      if (
        window.localStorage.getItem("token") == null &&
        this.$route.path != "/login"
      ) {
        this.$router.push("/login"); // redirect to home, for example
      }
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./App.scss";

.logo {
  width: 50px;
  height: 50px;
}
</style>

