export default {
  SET_devices: (state, devices) => {
    state.devices = devices;
  },
  SET_services: (state, services) => {
    state.services = services;
  },
  SET_spareParts: (state, spareParts) => {
    state.spareParts = spareParts;
  },
  SET_brandOptions: (state, brandOptions) => {
    state.brandOptions = brandOptions;
  },
  SET_colorOptions: (state, colorOptions) => {
    state.colorOptions = colorOptions;
  },
  SET_serviceOptions: (state, serviceOptions) => {
    state.technicalServiceDetails = [];
    state.serviceOptions = serviceOptions;
  },
  SET_sparePartOptions: (state, sparePartOptions) => {
    state.sparePartDetails = [];
    state.sparePartOptions = sparePartOptions;
  },
  SET_serviceNameOptions: (state, serviceNameOptions) => {
    state.serviceNameOptions = serviceNameOptions;
  },
  SET_serviceCategoryOptions: (state, serviceCategoryOptions) => {
    state.serviceCategoryOptions = serviceCategoryOptions;
  },
  SET_sparePartTypeOptions: (state, sparePartTypeOptions) => {
    state.sparePartTypeOptions = sparePartTypeOptions;
  },
  SET_sparePartCategoryOptions: (state, sparePartCategoryOptions) => {
    state.sparePartCategoryOptions = sparePartCategoryOptions;
  },
  SET_defectOptions: (state, defectOptions) => {
    state.defectOptions = defectOptions;
  },
  SET_serviceStatusTechs: (state, serviceStatusTechs) => {
    state.serviceStatusTechs = serviceStatusTechs;
  },
  SET_deviceTypes: (state, deviceTypes) => {
    state.deviceTypes = deviceTypes;
  },
  SET_deviceCategories: (state, deviceCategories) => {
    state.deviceCategories = deviceCategories;
  },
};
