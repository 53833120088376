import staffMutations from "./mutations.js";
import staffActions from "./actions.js";
import staffGetters from "./getters.js";

export default {
  namespaced: true, // put true if want enable modules
  state() {
    return {
      staffs: null,
      staff:null,
      counterGroups: null,
      counterGroup:null,
      counters: null,
      counter:null,
      outlets:null,
      outlet:null,
      organizations:null,
      organization:null,
      staffPositions:null,
      staffPosition:null,
      walkinServiceTypes:null,
      totalStaffs:null,
      totalCounterGroups:null,
      outletsOnly:null,
    };
  },
  mutations: staffMutations,
  actions: staffActions,
  getters: staffGetters,
};
