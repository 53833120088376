import receiptMutations from "./mutations.js";
import receiptActions from "./actions.js";
import receiptGetters from "./getters.js";

export default {
  namespaced: true, // put true if want enable modules
  state() {
    return {
      receipt: null,
      receipts: null,
      totalReceipts: null,
      completionImage:null,
    };
  },
  mutations: receiptMutations,
  actions: receiptActions,
  getters: receiptGetters,
};
